import React from 'react';
import { Link } from 'gatsby';
import BlogTitle from '../components/BlogTitle';
import Layout from '../components/Layout';

export default () => (
    <Layout>
        <div>
            <Link to="/">Home</Link>
            <BlogTitle headerText='Contact' />
            <a href="mailto:info@brickoven.de">Send a message!</a>
         </div>
    </Layout>

)